var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        id: "Capa_1",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 180.7 181.2",
        "xml:space": "preserve",
      },
    },
    [
      _c(
        "g",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "SVGID_2",
                gradientUnits: "userSpaceOnUse",
                x1: "39.8757",
                y1: "82.7427",
                x2: "149.5805",
                y2: "82.7427",
              },
            },
            [
              _c("stop", {
                staticStyle: { "stop-color": "#ff6800" },
                attrs: { offset: "0" },
              }),
              _c("stop", {
                staticStyle: { "stop-color": "#ff9000" },
                attrs: { offset: "1" },
              }),
            ],
            1
          ),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d: "M159.1,81.5H118c-4.4,0-7-4.9-4.5-8.5l25-34.6c1.7-2.5,1-5.9-1.5-7.6l-6.3-4.3c-2.5-1.7-5.9-1-7.6,1.5\n\t\tL94.8,67.6c-2.2,3.2-6.9,3.2-9,0L57,27.9c-1.7-2.5-5.1-3.1-7.6-1.5l-6.3,4.3c-2.5,1.7-3.1,5.1-1.5,7.6L67.2,73\n\t\tc2.5,3.6-0.1,8.5-4.5,8.5H21.6c-3,0-5.4,2.4-5.4,5.5v7.6c0,3,2.4,5.5,5.4,5.5h41.1c4.4,0,7,4.9,4.5,8.5l-25.1,34.4\n\t\tc-1.7,2.5-1,5.9,1.5,7.6l6.3,4.3c2.5,1.7,5.9,1,7.6-1.5l28.5-39.4c2.2-3.2,6.9-3.2,9,0l28.4,39.3c1.7,2.5,5.1,3.1,7.6,1.5l6.3-4.3\n\t\tc2.5-1.7,3.1-5.1,1.5-7.6l-25-34.3c-2.5-3.6,0.1-8.5,4.5-8.5h41.1c3,0,5.4-2.4,5.4-5.5V87C164.6,83.9,162.1,81.5,159.1,81.5z\n\t\t M98.6,92l-13.2,7.6c-1,0.6-2.3-0.1-2.3-1.3V83c0-1.2,1.3-1.9,2.3-1.3l13.2,7.6C99.6,89.9,99.6,91.4,98.6,92z",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }