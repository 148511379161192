var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.imgError
    ? _c("img", {
        class: _vm.imgClass,
        attrs: { src: _vm.imgSrc },
        on: {
          error: function ($event) {
            _vm.imgError = true
          },
        },
      })
    : _c(_vm.sunmediaIconComponent, { tag: "component", class: _vm.imgClass })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }