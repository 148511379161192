<template>
  <img v-if="!imgError" :src="imgSrc" :class="imgClass" @error="imgError = true" />
  <component :is="sunmediaIconComponent" v-else :class="imgClass" />
</template>

<script>
import { customImgTypes } from '@/utils/domain';
import SunMediaIconLight from '@/components/icons/SunMediaIconLight';
import SunMediaIconDark from '@/components/icons/SunMediaIconDark';

export default {
  name: 'CustomBrandImg',
  components: {
    SunMediaIconDark,
    SunMediaIconLight,
    SunMediaLogoLight: () => import('@/components/icons/SunMediaLogoLight'),
    SunMediaLogoDark: () => import('@/components/icons/SunMediaLogoDark'),
  },
  props: {
    type: {
      default: null,
      type: String,
    },
    imgClass: {
      default: '',
      type: String,
    },
    isIcon: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      urlImages: process.env.VUE_APP_IMAGES_URL,
      imgError: false,
      customImgTypes,
    };
  },
  computed: {
    isDarkType() {
      return this.type === this.customImgTypes.BG_LIGHT;
    },
    imgSrc() {
      return `${this.urlImages}/${window.location.hostname}/${this.type}`;
    },
    sunmediaIconComponent() {
      if (this.isIcon && !this.isDarkType) return 'SunMediaIconLight';
      if (this.isIcon && this.isDarkType) return 'SunMediaIconDark';
      if (!this.isIcon && !this.isDarkType) return 'SunMediaLogoLight';
      return 'SunMediaLogoDark';
    },
  },
};
</script>
